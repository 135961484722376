const JsSiteHeader = {
  init (Headroom, debounce) {

    /*
    * Site Header - headroom js
    */

    if ( $('.c-site-header').length ) {

      // grab an element
      var myElement = document.querySelector("header");
      // construct an instance of Headroom, passing the element
      var headroom  = new Headroom(myElement, {
        // offset: 250,
        offset : 5,
      });

      // initialise
      headroom.init();

      // $('#sitePrimaryMenu').on('show.zf.dropdownmenu', function () {
      //   console.log("Dropdown has been opened");

      //   Foundation.reInit('drilldown');
      // });

      // {
      //   // vertical offset in px before element is first unpinned
      //   offset : 0,
      //   // scroll tolerance in px before state changes
      //   tolerance : 0,
      //   // or scroll tolerance per direction
      //   tolerance : {
      //       down : 0,
      //       up : 0
      //   },
      //   // element which is source of scroll events. Defaults to window
      //   scroller : element,
      //   // css classes to apply
      //   classes : {
      //       // when element is initialised
      //       initial : "headroom",
      //       // when scrolling up
      //       pinned : "headroom--pinned",
      //       // when scrolling down
      //       unpinned : "headroom--unpinned",
      //       // when above offset
      //       top : "headroom--top",
      //       // when below offset
      //       notTop : "headroom--not-top",
      //       // when at bottom of scoll area
      //       bottom : "headroom--bottom",
      //       // when not at bottom of scroll area
      //       notBottom : "headroom--not-bottom"
      //   },
      //   // callback when pinned, `this` is headroom object
      //   onPin : function() {},
      //   // callback when unpinned, `this` is headroom object
      //   onUnpin : function() {},
      //   // callback when above offset, `this` is headroom object
      //   onTop : function() {},
      //   // callback when below offset, `this` is headroom object
      //   onNotTop : function() {}
      //   // callback at bottom of page, `this` is headroom object
      //   onBottom : function() {},
      //   // callback when moving away from bottom of page, `this` is headroom object
      //   onNotBottom : function() {}
      // }

      // Now handle Priority + Navigation
      // See: https://css-tricks.com/the-priority-navigation-pattern/
      // Also see: https://css-tricks.com/diy-priority-plus-nav/
      // Now see: https://codepen.io/lukejacksonn/pen/PwmwWV

      // Finally, see the solution we went with:
      // https://codepen.io/chriscoyier/pen/vOMBxp

      var PriorityNav = {

        // used multiple times, so saving.
        menu: $("#primaryMenuWrapper"),
        allNavElements: $("#primaryMenuWrapper > ul > li:not('#priorityPlusToggle')"),

        init: function() {
          this.bindUIActions();
          this.setupMenu();
        },

        setupMenu: function() {

          // Checking top position of first item (sometimes changes)
          var firstPos = $("#primaryMenuWrapper > ul > li:first").position();

          // Empty collection in which to put menu items to move
          var wrappedElements = $();

          // Used to snag the previous menu item in addition to ones that have wrapped
          var first = true;

          // Loop through all the nav items...
          PriorityNav.allNavElements.each(function(i) {

            var el = $(this);

            // ...in which to find wrapped elements
            var pos = el.position();

            if (pos.top !== firstPos.top) {

              // If element is wrapped, add it to set
              wrappedElements = wrappedElements.add(el);

              // Add the previous one too, if first
              if (first) {
                wrappedElements = wrappedElements.add(PriorityNav.allNavElements.eq(i-1));
                first = false;
              }
            }
          });

          if (wrappedElements.length) {

            // Clone set before altering
            var newSet = wrappedElements.clone();

            // Hide ones that we're moving
            wrappedElements.addClass("hide");

            // Add wrapped elements to dropdown
            $("#hiddenOffCanvasLinks").append(newSet);

            // Show new menu
            $("#priorityPlusToggle").removeClass("hide");

            // Make overflow visible again so dropdown can be seen.
            $("#primaryMenuWrapper").css("overflow", "visible");

          }
        },

        tearDown: function() {
          $("#hiddenOffCanvasLinks").empty();
          $("#priorityPlusToggle").addClass("hide");
          PriorityNav.allNavElements.removeClass("hide");
        },

        bindUIActions: function() {
          $("#priorityPlusToggle-title").on("click", function() {
            $("#hiddenOffCanvasLinks").toggleClass("show");
          });

          $(window)
            .resize(function() {
              PriorityNav.menu.addClass("resizing");
            })
            .resize(_.debounce(function() {
              PriorityNav.tearDown();
              PriorityNav.setupMenu();
              PriorityNav.menu.removeClass("resizing");
            }, 500));
        }

      }

      PriorityNav.init();

    }

  }
};

export default JsSiteHeader;
