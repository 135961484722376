import {
  mapStyles,
  icon,
  iconActive,
  mapOptions,
  mapOptionsRestricted,
} from './map-helpers';

const JsMap = {
  init () {

      // Only execute if gmaps exists.
      if ( $('.o-map').length ) {

        // Create new map function
      function new_map( $el ) {

        // var
        var $markers = $el.find('.marker');
        var usedOptions = mapOptionsRestricted;

        if ( $el.hasClass('unrestricted') ) {
          usedOptions = mapOptions;
        }

        // vars
        var args = {
          zoom: 7,
          scrollwheel: false,
          draggable: true,
          center: new google.maps.LatLng(0, 0),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          options: usedOptions
        };

        // create map
        var map = new google.maps.Map( $el[0], args);

        // add a markers reference
        map.markers = [];

        // add markers
        $markers.each(function(){
          add_marker( $(this), map );
        });

        // center map
        center_map( map );

        // return
        return map;
      }

      // Declare Infowindow
      var infowindow = new google.maps.InfoWindow();

      // Add marker(s) based on markup
      function add_marker( $marker, map ) {

        // var
        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        // create marker
        var marker = new google.maps.Marker({
          position  : latlng,
          map       : map,
          icon      : icon,
          optimized : false
        });

        // add to array
        map.markers.push( marker );

        // if marker contains HTML, add it to an infoWindow
        if( $marker.html() ) {

          google.maps.event.addListener(marker, 'mouseover', function() {
              this.setIcon(iconActive);
          });

          google.maps.event.addListener(marker, 'mouseout', function() {
              this.setIcon(icon);
          });

          google.maps.event.addListener(marker, 'click', function() {
            this.setIcon(iconActive);
            infowindow.setContent("<div class='infowindow-style'><div class='infowindow-style-item'>" + $marker.html()+'</div></div>' );
            infowindow.open(map, marker);
          });

          google.maps.event.addListener(infowindow,'closeclick', function() {
            // currentMark.setMap(null); //removes the marker
            marker.setIcon(icon);
          });
        } else {
          marker.setCursor('default');
        }

      }

      // Center Map based on markers
      function center_map( map ) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each( map.markers, function( i, marker ){

          var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

          bounds.extend( latlng );

        });

        // only 1 marker?
        if( map.markers.length == 1 ) {
          // set center of map
          map.setCenter( bounds.getCenter() );
          map.setZoom( 13 );
        } else {
          // fit to bounds
          map.fitBounds( bounds );
          map.setCenter( bounds.getCenter() );
          map.setZoom( 6 );
        }

      }

      var map = null;

      // Create Gmap Map
      $('.o-map').each(function() {

        // create map
        map = new_map( $(this) );

      });
      }
  }
};

export default JsMap;
