// NPM dependencies
import $ from 'jquery';
import 'babel-polyfill';
import 'foundation-sites';
import 'slick-carousel';
import 'lightgallery';
import 'lg-thumbnail';
import 'lazysizes';
import pictureFill from 'picturefill';
import objectFitImages from 'object-fit-images';
// import debounce from 'debounce';
import Headroom from 'headroom.js';
import 'lodash';

// Project Partials
import JsMap from '../../src/components/02-objects/map/map';

import JsGallery from '../../src/components/03-ui/gallery/gallery';
import JsSiteHeader from '../../src/components/03-ui/site-header/site-header';
import JsSlider from '../../src/components/03-ui/slider/slider';

// Note 1: if using VUE, your vue version and vue-template-compiler packages in package.json should match version numbers exactly.
// Note 2: if using VUE, import like this:

// import ExampleComponent from './components/ExampleComponent.vue';
// Vue.component('example-component', ExampleComponent);

$(document).ready(() => {
	// Init foundation on doc ready
	$(document).foundation();

  // Object Fit Polyfill
  // https://github.com/bfred-it/object-fit-images
	objectFitImages();

  // Picture Polyfill
  // http://scottjehl.github.io/picturefill/
	pictureFill();

	// Initialise imported js (this would be js required for every page, globally, i.e. headers
	JsMap.init();

	JsGallery.init();
	JsSiteHeader.init(Headroom);
	JsSlider.init();

	// Conditionally import and initialise additional chunks
	// See @ https://webpack.js.org/guides/code-splitting/ && https://sebastiandedeyne.com/posts/2018/code-splitting-with-laravel-mix
	// Depending on the page, these additional components may or may not be needed.
});

$(window).on('load', function () {
  // Fix Equalizer woes
	$(document).foundation();
	// Foundation.reInit('equalizer');
	Foundation.reInit($('[data-equalizer]'));
});
