let iconAnchor = new google.maps.Point(22, 22);
let iconSize = new google.maps.Size(44, 44);

export let icon = {
  url: window.location.protocol + "//" + window.location.hostname + '/assets/images/map/marker-pin.svg',
  anchor: iconAnchor,
  scaledSize: iconSize,
  size: iconSize,
  optimize: false
};

export let iconActive = {
  url: window.location.protocol + "//" + window.location.hostname + '/assets/images/map/marker-pin-active.svg',
  anchor: iconAnchor,
  scaledSize: iconSize,
  size: iconSize,
  optimize: false
};

export let mapStyles = [
   {
       "featureType": "administrative",
       "elementType": "all",
       "stylers": [
           {
               "saturation": "-100"
           }
       ]
   },
   {
       "featureType": "administrative.province",
       "elementType": "all",
       "stylers": [
           {
               "visibility": "off"
           }
       ]
   },
   {
       "featureType": "landscape",
       "elementType": "all",
       "stylers": [
           {
               "saturation": -100
           },
           {
               "lightness": 65
           },
           {
               "visibility": "on"
           }
       ]
   },
   {
       "featureType": "poi",
       "elementType": "all",
       "stylers": [
           {
               "saturation": -100
           },
           {
               "lightness": "50"
           },
           {
               "visibility": "simplified"
           }
       ]
   },
   {
       "featureType": "road",
       "elementType": "all",
       "stylers": [
           {
               "saturation": "-100"
           }
       ]
   },
   {
       "featureType": "road.highway",
       "elementType": "all",
       "stylers": [
           {
               "visibility": "simplified"
           }
       ]
   },
   {
       "featureType": "road.arterial",
       "elementType": "all",
       "stylers": [
           {
               "lightness": "30"
           }
       ]
   },
   {
       "featureType": "road.local",
       "elementType": "all",
       "stylers": [
           {
               "lightness": "40"
           }
       ]
   },
   {
       "featureType": "transit",
       "elementType": "all",
       "stylers": [
           {
               "saturation": -100
           },
           {
               "visibility": "simplified"
           }
       ]
   },
   {
       "featureType": "water",
       "elementType": "geometry",
       "stylers": [
           {
               "hue": "#ffff00"
           },
           {
               "lightness": -25
           },
           {
               "saturation": -97
           }
       ]
   },
   {
       "featureType": "water",
       "elementType": "labels",
       "stylers": [
           {
               "lightness": -25
           },
           {
               "saturation": -100
           }
       ]
   }
];

export let mapOptions = {
  styles: mapStyles,
  // center: {
  //   lat: -64.5,
  //   lng: 150.5
  // },
  // zoom: 8
};

export let mapOptionsRestricted = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: false,
  streetViewControl: false,
  signInControl: false,
  scrollwheel: false,
  scaleControl: true,
  rotateControl: false,
  panControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  draggable: false,
  cursor: 'default',
  draggableCursor: 'default'
};
