const JsSlider = {
  init () {

    if ( $('.c-slider').length ) {
      $('.c-slider').slick({
        // Lazyload all the things
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: '<div class="slick-prev" aria-hidden="true"><div>',
				nextArrow: '<div class="slick-next" aria-hidden="true"><div>',
        dots: true,
        draggable: true,
        pauseOnFocus: false,
        pauseOnHover: true,
        fade: true,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 5000,
      });
    }

    if ( $('.c-slider--cards').length ) {
      $('.c-slider--cards').slick({
        // Lazyload all the things
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        draggable: true,
        pauseOnFocus: false,
        pauseOnHover: true,
        fade: true,
        infinite: false,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 5000,
      });
    }

    if ( $('.c-slider--2-cards').length ) {
      $('.c-slider--2-cards').slick({
        // Lazyload all the things
        lazyLoad: 'ondemand',
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        dots: true,
        draggable: true,
        pauseOnFocus: false,
        pauseOnHover: true,
        fade: false,
        infinite: false,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              fade: true,
            }
          },
        ]
      });
    }

    if ( $('.c-slider--simple').length ) {
      $('.c-slider--simple').slick({
        // Lazyload all the things
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        draggable: true,
        pauseOnFocus: false,
        pauseOnHover: true,
        fade: false,
        infinite: false,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 5000,
      });
    }

  }
};

export default JsSlider;
